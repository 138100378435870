<template>
<div>
    <div id="partnersOptions" class="mb-4">
        <h6 class="filter-label" :title="'Use specific databases:'">
            <i class="fa fa-piggy-bank"></i> Project source
        </h6>
        <Multiselect
			:id="'authority_id'" mode="tags" label="label" valueProp="id" :searchable="false" placeholder="Select database sources"
			v-model="authority_id" :object="false" :canClear="true"
			:options="options" :groups="true"
		>
        <template v-slot:grouplabel="{group}">
			<span :class="'me-1 flag:'+group.flag"></span>
			<div class='ellipsis-text'>{{ group.label }}</div>
        </template>
		<template v-slot:tag="{ option, handleTagRemove, disabled }">
			<div class="multiselect-tag is-user country"
			 :class="{'is-disabled': disabled}">
			<span :class="'me-1 flag:'+option.flag"></span>
			<div class='ellipsis-text'>{{ option.label }}</div>
			<span v-if="!disabled"
				 class="multiselect-tag-remove"
    				@click="handleTagRemove(option, $event)">
				<span class="multiselect-tag-remove-icon"></span>
			</span>
			</div>
		</template>
		</Multiselect>
        <FilterSlider field='authority_id'/>
    </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import FilterSlider from '../Sidebar/Filters/FilterSlider.vue'
export default {
    name: "PartnersOptions",
    components: {Multiselect, FilterSlider},
    computed: {
        "authority_id": {
            get: function() {return this.$store.state.filters['authority_id']? this.$store.state.filters['authority_id']['values']:[]},
            set: function(value) {
				this.$store.commit('SET_FILTER', {
					field: 'authority_id', 
					type: this.$store.getters.getExcludedFilterFields.includes('authority_id')?'exclude':'include',
					values: value
                }
                )	
			}
        }
    },
    data: function() {return({
        options: [
            {label: 'CORDIS (EU)', flag: 'EU',options: [{'id': "identifier~~48",'label': 'Cordis (EU)', flag:'EU'}]},
            {label: 'FRIS (BE)', flag: 'BE', options: [
                {id: "identifier~~56", flag: 'BE', label: 'FRIS-VUBRUSSEL'},
                {id: "identifier~~57", flag: 'BE', label: 'FRIS-ILVO'},
                {id: "identifier~~58", flag: 'BE', label: 'FRIS-UGENT'},
                {id: "identifier~~61", flag: 'BE', label: 'FRIS-UHASSELT'},
                {id: "identifier~~62", flag: 'BE', label: 'FRIS-UANTWERPEN'},
                {id: "identifier~~63", flag: 'BE', label: 'FRIS-KULEUVEN'},
                {id: "identifier~~64", flag: 'BE', label: 'FRIS-INBO'},
                {id: "identifier~~65", flag: 'BE', label: 'FRIS-ITG'},
            ]},
            // {label: 'GEPRIS (DE)', flag: 'DE',options: [{'id': "identifier~~66",'label': 'GEPRIS (DE)', flag:'DE'}]},
            {label: 'NARCIS (NL)', flag: 'NL',options: [{'id': "identifier~~49",'label': 'NARCIS (NL)', flag:'NL'}]},
            {label: 'NSF (US)', flag: 'US',options: [{'id': "identifier~~55",'label': 'NSF (US)', flag:'US'}]},
            {label: 'SWECRIS (SE)', flag: 'SE',options: [{'id':"identifier~~59",'label': 'SWECRIS (SE)', flag:'SE'}]},
            {label: 'UKRI (UK)', flag: 'GB',options: [{'id': "identifier~~60",'label': 'UKRI (UK)', flag:'GB'}]},
        ]
    })},
    props: ["list"],
    methods: {
    },
}
</script>

<style>
.country{ 
	background:#f2f1f0;
	color:var(--secondary_color);
}
#partnersOptions .multiselect-option {
    display:none!important;
}
</style>