<template>
    <div @click="show.description=1-show.description" class="d-flex justify-content-between list-item align-items-center" role="button">
        <div v-if="work['fields']">
            <span class="source-cell" v-if="work['fields']['publication_date']">
                <span class="d-inline-block text-decoration-none me-2" target="_blank">Date: {{new Date(work.fields.publication_date).toLocaleDateString()}}</span>
                <a class="d-inline-block me-2" target="_blank" :href='get_best_link(work)'>
                    Journal: 
                        <span v-if="work.fields.primary_location && work.fields.primary_location.source">{{work.fields.primary_location.source.display_name}}</span>
                        <span v-else>Publisher</span>
                </a>
            </span>
            <h5 class="my-2 publication_title" v-html="work.fields.display_name"></h5>
            <div class="work-authors mb-1">
                <a class="me-1" 
                    v-for="authorship in work.fields.authorships.slice(0,max_author_limit+((work.fields.authorships.length-3)*show.description))" 
                    @click.stop="handle_author_click($event, authorship)"
                    :title="'Click show profile of ' + authorship.author.display_name"
                    :key="authorship.author.id">
                    {{authorship.author.display_name}}
                </a>
                <a class="d-flex align-items-center" v-if="!show.description && work.fields.authorships.length > max_author_limit">Show more <i class="ms-1 fa fa-caret-right"></i></a>
                <a class="d-flex align-items-center" v-if="show.description && work.fields.authorships.length > max_author_limit">Hide <i class="ms-1 fa fa-caret-left"></i></a>
            </div>
            <div>
                <div v-if="show.description">
                    <div class="d-inline" v-if="work.fields.highlights !== undefined" v-html="work.fields.highlights.abstract.full"></div>
                    <div class="d-inline" v-else v-html="work.fields.abstract"></div>
                </div>
                <div v-else>
                    <div class="d-inline" v-if="work.fields.highlights !== undefined" v-html="work.fields.highlights.abstract['150']"></div>
                    <div class="d-inline" v-else-if="work.fields.abstract!==undefined" v-html="work.fields.abstract.slice(0, 150)"></div>
                    <div class="d-inline" v-else>Abstract not available</div>
                    <div class="d-inline" v-if="(work.fields.abstract!==undefined) && (work.fields.abstract.length > 150)">...</div>
                </div>
            </div>
            <div class="d-flex mt-2">
                <LabelBox in_popup="in_popup" v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')" :item="work"></LabelBox>
                <a v-if="work.fields.open_access.oa_url!=null" class="btn-primary btn-small me-2" target="_blank" :href='work.fields.open_access.oa_url'>
                    Download <span class="text-emphasis">PDF</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import LabelBox from '../LabelBox.vue'
import shared from '../shared.js'

export default {
    name: "Work",
    props: ["work", "in_popup"],
    components: {LabelBox},
    data: function() {return({
        max_author_limit: 3,
        show: {
            description: 0,
        }
        })
    },
    computed: {
        to_dict: function() {
            return({
                'identifier': this.work.fields.id,
                'fields': this.work.fields,
                'record_type': 'work'
            })
        }
    },
    methods:{
        handle_author_click: function($event, authorship) {
            if(!this.in_popup) {
                this.$store.dispatch('get_item', {'popup_anchor': $event.target, 'item_id': authorship.author.id, 'item_data': {'record_type': 'author'} })
            }
            else {
                let author = shared.make_author({
                    identifier: authorship.author.id,
                    display_name: authorship.author.display_name,
                })
                this.$store.dispatch('more_like_expert', author);
            }        
        },
        get_best_link: function(work) {
            if (typeof work.fields.doi !== 'undefined' && work.fields.doi) {
                return work.fields.doi }
            else if (typeof(work.fields.primary_location) !== 'undefined' && typeof(work.fields.primary_location?.landing_page_url) !== 'undefined') {
                return work.fields.primary_location.landing_page_url
            }
            else {
                return work.fields.id
            }
        }
    },
    mounted: function() {
        if (this.in_popup) {
            this.show.description=1
        }
        else {
            this.show.description = 0
        }
    }
}
</script>

<style>
    .source-cell, .source-cell a {
        min-width:0;
        display:flex;
        flex-direction:row;
        justify-content: flex-start;
        color: var(--grey_text);
    }
    .source-cell a:hover {
        color:var(--primary_color)!important;
    }
    .work-authors {
        display:flex;
        align-items:center;
        flex-wrap:wrap;
    }
    .work-authors a {
        border-radius:5px;
        border: 1px solid var(--primary_color);
        text-decoration:none;
        color:var(--primary_color);
        background:white;
        padding: 0 .5rem;
        margin: .25rem .25rem .25rem 0;
        font-size:.8rem;
    }
    .work-authors a:hover {
    background:var(--primary_color);
    color:white;
}
    .publication_title {
        font-size:24px;
        color:var(--secondary_color);
        max-width:1200px;
    }
    em {
        font-weight:800;
        font-style:normal;
    }
    .text-emphasis {
        font-weight:700;
        color:var(--primary_color);
    }
    .btn-primary:hover .text-emphasis {
        color:white;
    }
</style>