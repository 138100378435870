<template>
    <div class="mb-4">
        <div class="sidebar-title" title="Filter by the types of source, e.g. Journal or Conference Series.">Source type</div>
        <Multiselect
            mode="single" label="name" valueProp="id" :searchable="false" placeholder="Select a source type" 
            v-model="source_type" :object="false" id="source_type_multiselect" :closeOnSelect="true" :closeOnDeselect="true"
            :options="available_source_types" :class="{'active' : (source_type !== 'Any' && source_type !== null)}"
        >
            <template v-slot:option="{ option }">
              <span class="capitalize">{{ option.name }}</span>
            </template>
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label capitalize">{{ value.name }}</div>
            </template>
        </Multiselect>
        <FilterSlider field='source_type'/>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import FilterSlider from './FilterSlider.vue'

export default {
    // This Filter uses OpenAlex SourceType. We want to be able to differentiate
    // between preprints and peer-reviewed journal articles, and that is not in
    // the OpenAlex document type field.
    name: "SourceTypeFilter",
    components: {Multiselect, FilterSlider},
    computed: {
        "source_type": {
            get: function() {
                let value = this.$store.state.filters['source_type']?.values
                if (!value || value == []) {return "any"}
                else {return value}
            },
            set: function(value) {
                if (value == "any" || value == null) {
                    value = []
                }
                else {
                    value = [value]
                }
                this.$store.commit('SET_FILTER', {'field': 'source_type', 'values': value, 'type': 'include'})
            }
        },
    },
    data: function() {return({
            available_source_types: ["journal", "ebook platform", "conference", "book series", "repository", "any"]
        })
    }
}
</script>

<style>

</style>