<template>
  <div class="home" :class='
    {
      "bg-at": $store.state.user_profile.organisation.name == "AcademicTransfer",
      "bg-gc": $store.state.user_profile.organisation.name != "AcademicTransfer",
    }'
    :style="getColorStyling()">
    <div id="app-content" class="">
      <div id="top-container" :class="{
          'stick-to-top':show.emphasis=='SimilarResults',
          'full-height':show.emphasis!='SimilarResults'
        }">
        <Navbar />
        <div id="search-bar" :class="{'minimized': show.emphasis=='SimilarResults'}">
            <HomeSearchModuleSelector key="module-selector"></HomeSearchModuleSelector>
            <SearchBox key="form"></SearchBox>
        </div>
      </div>
      <Spinner key="spinner" v-if="$store.state.show.results_loading" />
      <SimilarResults v-else key="result_container"/>
        <div id="hovering_author_container" v-show="$store.state.show.graph_author_hover"></div>
        <div id="tooltip_container">
          <Tooltip :key="idx" :idx="idx" :tooltip="tooltip" v-for="(tooltip, idx) in $store.state.tooltips"/>
        </div>
    </div>
    <div v-show="show.dropdown" class="dropdown_overlay" @click.stop="$store.commit('SET_DROPDOWN', null)"></div>
  </div>
</template>

<script>
// Components
import HomeSearchModuleSelector from '../components/HomeSearchModuleSelector.vue'
import SearchBox from '../components/SearchBox.vue'
import SimilarResults from '../components/SimilarResults.vue'
import Navbar from '../components/Navbar.vue'
import Tooltip from '../components/Tooltip.vue'
import shared from '../components/shared.js'
import { mapState } from 'vuex'
import Spinner from '../components/Spinner.vue'

export default {
  name: 'Home',
  components: {
    SearchBox, HomeSearchModuleSelector, SimilarResults, Navbar, Tooltip, Spinner
  },
  data: function() {return({
    });
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
    let search_modules = JSON.parse(document.getElementById('search-modules-data').text)
    this.$store.commit('SET_SEARCHMODULES', search_modules)
    // first, we check if the query is opened in a new tab
    if (this.$route.query.query_key !== undefined) {
      if(this.$store.state.user_profile.default_filter_preset !== null) {
        this.$store.dispatch('load_filter_preset', this.$store.state.user_profile.default_filter_preset)
      }
      let query_key = this.$route.query.query_key
      this.$store.dispatch('load_query_from_localstorage', query_key)
    }
    // second, we see if the users is reloading a collection (and the attached last query).
    else if (this.$route.params.collection !== undefined) {
      this.$store.dispatch('load_stored_collection', this.$route.params.collection)
    }
    // third, a user could be reloading a query:
    else if (this.$route.params.query !== undefined) {
      this.$store.dispatch('load_request_and_response_from_db_with_uuid', {last_query_uuid: this.$route.params.query})
    }
    // if not, we set the filter preset if one is active for this user.
    else if(this.$store.state.user_profile.default_filter_preset !== null) {
        this.$store.dispatch('load_filter_preset', this.$store.state.user_profile.default_filter_preset)
    }
  },
  computed: {
    ...mapState(["show"]),
  },
  mounted: function() {
    window.addEventListener("popstate", (event) => {
      this.$store.dispatch('load_query_from_history', event)
    });
  },
  methods: {
      getColorStyling: function() {
        return shared.colorMap(this.$store.state.user_profile.organisation.name)
      }
  }
}
</script>

<style>

#top-container {
  transition: background-color ease var(--slow_anim);
}
.full-height {
  background-color:rgba(255, 255, 255, 0)
}
.stick-to-top {  
  position:sticky;
  top:0;
  box-shadow: 0px 3px 6px #0000001A;
  background-color:rgb(255, 255, 255, 1);
  z-index:6;
}
#search-bar{
  width: 100%;
  max-width: 1920px;
  height: calc(100vh - 90px);
  margin: auto;
  position: relative;
  transition: height ease var(--slow_anim);
  overflow-y: hidden;
}
#search-bar.minimized {
  height:var(--searchbar_height);
  overflow-y:visible;
  margin: .5rem auto;
}

/* MOBILE */
@media (max-width: 992px) {
  #search-bar.minimized {
    height:auto;
  }
}

#search-bar > div {
  transition: all ease var(--slow_anim);
}

 /* Button styling */
.home .btn-primary {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color:var(--primary_color);
    border-radius:var(--ms-radius);
    box-shadow: var(--box_shadow_emphasis);
    background: white;
}
.home .btn-primary.active {
    color:var(--primary_color)
}
.home .btn-primary:hover {
  background-color:var(--primary_color);
  color:white;
}
input[type="checkbox"] {
  accent-color: var(--primary_color);
}
.dropdown_overlay {
    position:fixed;
    bottom:0;
    top:0;
    left:0;
    right:0;
    z-index:3;
}
</style>