<template>
    <div class="mb-4" >
        <div class="sidebar-title">Active Filter preset</div>
        <Multiselect
            :attrs='{"autocomplete": "one-time-code"}'
            :closeOnSelect="true"
            :closeOnDeselect="true"
            :class="{ active: active_filter_preset }"
            :delay="150" 
            id="active_preset_filter_multiselect"
            :loading="show.loading"
            label="name"
            noOptionsText="Type to find a stored preset"
            mode="single"
            :min-chars="3" 
            :object="true" 
            :options="query_filter_presets"
            placeholder="Select a preset filter"
            :searchable="true"
            @select="select_filter_preset"
            @clear="$store.dispatch('clear_active_filter_preset')"
            :value="active_filter_preset"
            valueProp="uuid"
        >
        </Multiselect>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
    name: "FilterPresetSelector",
    components: {Multiselect},
    computed: {
        active_filter_preset: {
            get() {return this.$store.state.active_filter_preset}
        }
    },
    data: function() {return({
        show: {
            loading:0,
        }
    })},
    methods: {
        query_filter_presets: async function(q) {
            if (!q || q.length < 3) {
                return false
            }
            let _this = this
            this.show.loading = 1
            return await this.$axiosQ.jumpQueue('gcapi', {
                method: 'get', 
                url: "/api/filter_preset/search/",
                params: {q: q}}
            ).then(function(response) {
                _this.show.loading = 0
                return response.data;
            })
        },
        select_filter_preset: function(preset, option) {
            if(!preset) {
                this.$store.dispatch('clear_active_filter_preset')
            }
            else {
                this.$store.dispatch('load_filter_preset', option)
            }
        }
    }
}
</script>

<style>

</style>