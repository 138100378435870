<template>
  <div @click.stop="">
    <div class="position-absolute p-4 float-end close-button close-button-work" @click="$store.commit('CLOSE_MODAL')"><i class="fa fa-close"></i></div>
        <h3 class="text-center modal-title m-4">{{modals.coi_details.check.display_name}} with {{modals.coi_details.against.display_name}}</h3>
        <div class="modal-content">
            <div class="affiliation-box m-4" v-if="shared_affiliations.length">
                <h5>Shared affiliations</h5>
                <table class="table table-striped">
                    <thead>
                        <th>Institution</th>
                        <th class="text-center" :key="'h'+year" v-for="year in timeline">{{year}}</th>
                    </thead>
                    <tbody>
                        <template :key="'rows'+institution_id" v-for="institution_id in shared_affiliations">
                            <tr >
                                <th rowspan="2">{{modals.coi_details.institutions[institution_id].display_name}}</th>
                                <td :key="institution_id+'int'+year" v-for="(author_columns, year) in get_author_colspans(modals.coi_details.affiliation_years[0][institution_id])" :colspan="author_columns.span" class="text-center">
                                    <span class="w-100 badge bg-success" v-if="author_columns.active">{{modals.coi_details.check.display_name}}</span>
                                </td>
                            </tr>
                            <tr>
                                <!-- <th></th> -->
                                <td :key="institution_id+'int'+year" v-for="(author_columns, year) in get_author_colspans(modals.coi_details.affiliation_years[1][institution_id])" :colspan="author_columns.span" class="text-center">
                                    <span class="w-100 badge bg-info" v-if="author_columns.active">{{modals.coi_details.against.display_name}}</span>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <h5 class="mx-4">Publications</h5>
            <div class="result_list">
                <div class="m-4" :key="'shared_pub'+publication.id" v-for="publication in modals.coi_details.works">
                    <Work class="" :work='{fields: publication}' />
                    <hr/>
                </div>
            </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Work from '../Work/Work.vue'

export default {
    name: 'CoAuthorModal',
    props:['modal'],
    components: {Work},
    computed: {
        ...mapState(['modals']),
        timeline: function() {return _.range(this.modals.coi_details.start_year, new Date().getFullYear()+1, 1)},
        
        shared_affiliations: function() {
            let _this = this;
            // this.modals.coi_details.affiliation_years [0: {I1: 2020, 2021}, 1: {I2, 2019 etc}]
            return _.intersection(
                Object.keys(_this.modals.coi_details.affiliation_years[0]), 
                Object.keys(_this.modals.coi_details.affiliation_years[1])
            )
        }
    },
    data: function() {return({
    })},
    mounted: function() {
    },
    methods: {
        // this function turns a series of years [2024, 2023, 2022, 2018, 2019] into 
        // colspans {2018: {span:2, active: true}, 2020: {span: 1, active: false}, 2021: {span: 1, active:false} 2022: {span: 3, active:true}}
        get_author_colspans: function(affiliation_years) {
            let response = {}
            let years_in_chain = []
            this.timeline.forEach(year => {
                if(affiliation_years.includes(year) && !years_in_chain.includes(year)) {
                    let i = 1
                    while (affiliation_years.includes(year + i)) {
                        years_in_chain.push(year+i)
                        i++
                    }
                    response[year] = {
                        active: 1,
                        span: i
                    }
                }
                else if(!years_in_chain.includes(year)) {
                    response[year] = {
                        active:0,
                        span:1,
                }}
            })
            return response
        },
    }
}
</script>

<style scoped>
.result_list {
    padding-bottom:1.5rem;
    max-width:80vw;
}
.affiliation-box {
    display:flex;
    flex-direction:column;
    text-align:left;
    position:sticky;
}
.modal-title {
    position:sticky;
}
.modal-content {
    max-height: calc(80vh - 90px);
    overflow:auto;
}
</style>