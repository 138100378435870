<template>
    <div class="result-row d-flex flex-column p-4 align-items-start justify-content-between">
        <div class="d-flex justify-content-between flex-grow-1 w-100">
            <div class="h5 mb-2">
                {{item.fields.submission_title}}
                <GreyDivider class="mx-2"/>
                <small>{{item.fields.company_name}}</small>
            </div>
            <div class="hide_result_item user-select-none" 
                v-if="$store.getters.getCurrentlyAvailableFilters.includes('excluded_records')" 
                @click="hide_result_item(item)">
                <i class="fa fa-xmark"></i>
            </div>
        </div>
        <div class="expert-links">
            <div class="profile-links">
                <!-- <span class="float-start badge bg-warning"><i class="fa fa-bullhorn"></i> {{item.fields. challenge}}</span><br/> -->
                <a v-if="item.fields.company_website" class="" target="_blank" :href="'https://'+item.fields.company_website.replace('https://', '')">Website</a>
                <a v-if="item.fields.origin_url" class="" target="_blank" :href="item.fields.origin_url">Source</a>
                <a v-if="item.fields.email !== 'False'" class="" target="_blank" :href="'mailto:'+item.fields.email">Mail</a>
                <GreyDivider class="mx-2"/>
                <div>{{item.fields.origin_content_type}}</div>
                <GreyDivider class="mx-2"/>
                <div>
                    {{new Date(1000*item.fields.creation_date_time).toLocaleString()}}
                </div>
            </div>
        </div>
        <div class="mb-2">
            <div v-html="item.fields.content?.replace('_', '<br/>')"></div>
        </div>
        <label-box :item="item" v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')"></label-box>
    </div>
</template>

<script>
import LabelBox from '../LabelBox.vue'
import GreyDivider from "../GreyDivider.vue";

export default {
  components: { LabelBox, GreyDivider },
    name: "Starthub",
    data: function() {return({
        show: {
            description: 1,
        }
    })},
    props: ["item"],
    methods: {
        hide_result_item: function(record) {
        this.$store.state.stored_collection.excluded_records.push(record)
        },
    }
}
</script>

<style scoped>

</style>