<template>
  <div id="collection-sidebar" class="justify-content-end">
    <div v-if="$store.state.stored_collection.collection.records.length || $store.state.stored_collection.excluded_records.length">
      <div class="h5 mb-4 d-inline-block" id="collection_title">{{get_collection_name()}}</div>
      <div class="d-flex flex-column mb-4" :key="'records'+type" v-for="(records, type) in this.$store.getters.getCollectionByType">
        <div @click="toggle_type(type)" role="button" class="collection-type d-flex justify-content-between user-select-none align-items-center">
          <span class="sidebar-title capitalize">{{type}} ({{records.length}})</span>
          <span class="animate-flip"  :class="{'flip-y': show[type]}">
            <i class="fa fa-chevron-up"></i>
          </span>
        </div>
        <div v-show="show[type]">
          <div class="collection-item" :key="'record'+record.fields.id" v-for="record in records">
            <span class="collection-title">{{record.fields.display_name}}</span>
            <div class="d-flex">
              <span v-if="$store.state.stored_collection.records_used_in_search.includes(record.identifier)" 
                    title="Click to ignore from search"
                    class="collection-item-button search-active"
                    @click="toggle_used_in_search(record)">
                <i  class="fab fa-searchengin "></i>
              </span>
              <span class="collection-item-button search-inactive" v-else @click="toggle_used_in_search(record)" title="Click to use in search.">
                <i class="fab fa-searchengin"></i>
              </span>
              <span class="ms-2 collection-item-button remove" @click="remove_item(record)">
                <i class="fa fa-xmark"></i>
              </span>
            </div>        
          </div>
        </div>
      </div>
      <HiddenAuthorList v-if="filter_available('excluded_records')"/>       
      <div id="collection-buttons" class="mt-4">
        <div class="w-100 btn-collection" id="save-collection" v-if="show.saving" @click="save_collection()">
            Saving...
            <div class="collection_input" v-if="show.saving">
              <input type="text" id="collection-title-edit" class="form-control" @click.stop="" @keyup.enter="save_collection()" v-model="$store.state.stored_collection.name" placeholder="Please name your collection" />
              <span class="save-button"><i class="fa fa-save"></i></span>
            </div>
        </div>
        <div class="w-50 d-inline-block">
          <div class="btn-collection" id="save-collection" v-if="!show.saving" @click="show.saving = 1">
            Save
          </div>
          <ExcelExport :exporting_items="$store.getters.getCollectionByType" label="Export" :icon="false" classes="btn-collection">
            Export
          </ExcelExport>
        </div>
        <div class="w-50 d-inline-block">
          <div class="btn-collection red" v-if="!show.saving" @click="$store.commit('CLEAR_COLLECTION')">Clear</div>
          <div class="btn-collection" @click="$store.dispatch('get_similarity')">Update</div>
        </div>
      </div>
    </div>
    <div class="mt-4" v-else>
      <div>
        <span class="collection-text-emphasis">ADD</span> items to your collection to save results.
      </div>
      <div class="mt-4">Your collection is used in search to find similar items.
      </div>
    </div>
  </div>
</template>

<script>
import shared from '../shared'
import ExcelExport from '../ExcelExport.vue'
import HiddenAuthorList from './HiddenAuthorList.vue'

export default {
  name: "Collection",
  components: {ExcelExport, HiddenAuthorList},
  data: function() {return({
    show: {
      saving: 0
    }
  })},
  methods: {
      get_icon: function(result_type) {
          return shared.result_type_to_icon(result_type)
      },
      remove_item(record) {
        this.$store.commit('REMOVE_FROM_COLLECTION', record)
      },
      get_collection_name: function() {
          if(!this.$store.state.stored_collection.collection.records.length &&
             !this.$store.state.stored_collection.excluded_records.length) {
              return 'Empty collection'
          }
          else if (!this.$store.state.stored_collection.pk) return 'Unsaved collection'
          else return this.$store.state.stored_collection.name
      },
      set_collection_name: function(e) {
        this.$store.commit('SET_COLLECTION_NAME', e.target.innerText)
      },
      save_collection: function() {
        this.$store.dispatch('save_collection')
        this.show.saving = 0
      },
      toggle_type: function(type) {
          if(!this.show[type]) {
            this.show[type] = 1
          }
          else {
            this.show[type] = 1 - this.show[type]
          }
      },
      toggle_used_in_search: function(record) {
        let record_index = this.$store.state.stored_collection.records_used_in_search.indexOf(record.identifier)
        if (record_index === -1) {
          this.$store.state.stored_collection.records_used_in_search.push(record.identifier)
        }
        else {
          this.$store.state.stored_collection.records_used_in_search.splice(record_index, 1)
        }
      },
      filter_available: function(field_name) {
            return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
      },
  }
}
</script>

<style>

#empty_collection {
  width:100%;
}
.collection-item {
  display:flex;
  justify-content:space-between;
  box-shadow:var(--box_shadow_emphasis);
  border-radius:var(--ms-radius);
  margin: .25rem 0;
  padding: .25rem .5rem;
  color:var(--orange_highlight);
  background-color:white;
}
.collection-title {
  max-height:1.4rem;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.collection-color {
  color: var(--orange_highlight);
}
.collection-item .title {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.collection-item-button:hover {
  cursor:pointer;
  font-weight:700;
}
.collection-item-button.search-active {
  color:var(--primary_color);
}
.collection-item-button.search-inactive {
  color:var(--grey_text);
}
.collection-item-button.remove{
  color:var(--grey_text);
}
.collection-item-button.remove:hover{
  color:var(--orange_highlight);
}
#collection-buttons {
  margin: 1rem -1rem -1rem -1rem;
}
#collection_title {
  max-width:100%;
  word-wrap:break-word;
}
.collection-type:hover svg {
  color:var(--primary_color);
}
.animate-flip svg {
    transition: transform ease var(--fast_anim);
}
.animate-flip.flip-y svg {
    transform: scaleY(-1);
}
.collection_input {
  position:relative;
  left:0;
  top:5px;
}
#collection-title-edit {
  padding:4px 20px 4px 8px!important;
}
.save-button {
  position:absolute;
  color:var(--primary_color);
  top:.2rem;
  right:.3rem;
}
.save-button:hover {
  color:var(--orange_highlight);
}
.btn-collection {
  flex-grow:1;
  cursor:pointer;
  background:white;
  color:var(--grey_text);
  padding:1rem;
  text-align:center;
}
.btn-collection.red:hover {
  background:var(--bs-danger);
}
.btn-collection:hover {
  box-shadow: inset 0px 3px 6px #00000029;
  background-color:var(--primary_color);
  color:white;
  font-weight:700;
}
</style>