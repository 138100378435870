<template>
<div class="filter-row text-left">
    <WorksRangeFilter/>
    <div class="mb-4" title="Use only the first or last author of matching papers.">
        <div class="sidebar-title">First/Last author</div>
        <Multiselect
            :object="false"
            :valueField="'value'"
            id="author_position_select"
            :class="{'active': (this.author_weight !== 'any' && this.author_weight !== null)}"
            :options="[
                {'label': 'Any Author', 'value': 'any'},
                {'label': 'First Author', 'value': 'first'},
                {'label': 'Last Author', 'value': 'last'},
            ]"
            v-model="author_weight"/>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import Multiselect from '@vueform/multiselect'
import WorksRangeFilter from './Filters/WorksRangeFilter.vue'

export default {
  
    name: "ExpertsOptions",
    components: {Multiselect, WorksRangeFilter},
    computed: {...mapState(['co_authors', 'query_uuid', 'co_author_field', 'user_profile']),
        author_weight: {
            get: function(){
                return this.$store.state.filters['author_position']?this.$store.state.filters['author_position']['value']:'any'
            },
            set: function(value) {
                let filter = {
                    value: value,
                    type: 'author_position',
                    field: 'author_position'
                }
                this.$store.commit('SET_FILTER', filter)
            },
        }
    }
}
</script>

<style scoped>
.btn-secondary {
    padding:0px 4px;
}
.mw-174 {
    max-width:174px;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>