<template>
    <div class="mb-4" title="Include or exclude specific institutions">
        <div for="oa_institutions_query" class="sidebar-title">Specific institution</div>
        <Multiselect
            mode="tags" 
            label="display_name" 
            valueProp="id" 
            :searchable="true" 
            placeholder="Find an institution"
            v-model="authors_institution_id" 
            :object="false" 
            :filterResults="false" 
            ref="institution_id_multiselect"
            id="institution_id_multiselect" 
            :canClear="false"
            :attrs='{"autocomplete": "one-time-code"}'
            :class="{'active' : authors_institution_id?.length}"
            :noOptionsText="'Search by institution name (English)'" 
            :min-chars="3" 
            :delay="150" 
            :options="query_institutions">
            <template v-slot:option="{ option }">
                <div class="d-flex justify-items-between">
                    <span>{{ option.display_name }}</span>
                    <span> <i class="fas fa-book mx-1"></i> {{option.works_count}}</span>
                </div>
            </template>
            <template v-slot:caret={}>
                <span v-if="!institution_name_has_options"><i class="fas fa-search me-2 text-greyed-out"></i></span>
                <span v-else class="multiselect-caret" aria-hidden="true"></span>
            </template>
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag is-user country" :class="{'is-disabled': disabled}" :title="option.display_name">
                    <div class='ellipsis-text'>{{ option.display_name }}</div>
                    <span v-if="!disabled"
                        class="multiselect-tag-remove"
                        @click="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                    </span>
                </div>
            </template>
        </Multiselect>
        <FilterSlider field='institution.id'/>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import shared from '../../shared.js'
import FilterSlider from './FilterSlider.vue'

export default {
    name:'InstitutionNamefilter',
    components: {Multiselect, FilterSlider},
    computed: {
        authors_institution_id: {
            get() {
                return this.$store.state.filters['institution.id']?this.$store.state.filters['institution.id']['values']:[]
            },
            set(value) {
                this.$store.dispatch('update_filter', {field_name:'institution.id', 'values':value, 'options': this.$refs.institution_id_multiselect.iv})
            }
        },
    },
    data: () => { return ({
        institution_name_has_options: false,
    })},
    methods: {
        query_institutions: async function(q) {
            let _this = this;
            if (!q) {
                if(this.$store.state.filters['institution.id']) {return this.$store.state.filters['institution.id']['options']}
                else return false
            }
            return await this.$axiosQ.addToQueue('openalex', {
                method: 'get', 
                url: "https://api.openalex.org/autocomplete/institutions?q=" + shared.latinize(q)}, 3).then(function(response) {
                let short_ids = response.data.results;
                _this.institution_name_has_options = (short_ids.length && short_ids.length > 0);
                return short_ids;
            })
        }
    }
}
</script>

<style>

</style>