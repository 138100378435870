<template>
      <div id="works-count-filter" title="Publication should be published before or after a date." class="mt-4">
        <div class="sidebar-title">Publication date</div>
        <input id="pub-date" class="multiselect d-block datepicker-custom" v-model="datepicker_value" min="2018-01-01" type="date"/>
        <!-- CSS for slider in FilterSlider.vue -->
        <div class="slider" role="button"
            :class="filter_verb"
            @click="flip_range()">
            <span class="slider-text capitalize">{{filter_verb}}</span>
            <div class="slider-space">
                <span class="slider-toggle"></span>
            </div>
    </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'DateRangeFilter',
    props: ['field'],
    computed: {
        ...mapState(['filters']),
        datepicker_value: {
            get() {
                if (!this.$store.getters.getFilterByField(this.field)) return null
                let unix_timestamp = this.$store.getters.getFilterByField(this.field)[this.start_or_end]
                return new Date(1000*unix_timestamp).toISOString().split('T')[0]
            },
            set(value) {
                if (!value || value.length < 1) {
                    console.log('clearing value')
                    this.$store.commit('CLEAR_FILTER', {field: this.field})
                }
                else {
                    let filter_obj = {
                        field: this.field,
                        type: 'range',
                        start: null,
                        end: null
                    }
                    filter_obj[this.start_or_end] = new Date(value).getTime() / 1000
                    console.log('setting filter object')
                    console.log(filter_obj)
                    this.$store.commit('SET_FILTER', filter_obj)
                }
            }
        },
        start_or_end: function() {
            return this.filter_verb == 'before' ? 'end' : 'start'
        },
        filter_verb: function() {
            if (this.$store.getters.getFilterByField(this.field) && this.$store.getters.getFilterByField(this.field)['end']) {
                return 'before'
            }
            else return 'after'
        },
    },
    methods: {
        flip_range: function() {
            var filter_before_flipping = this.$store.getters.getFilterByField(this.field)
            // if there is no filter, we create one that is flipped by default.
            var filter_after_flipping = {
                field: this.field,
                type: 'range',
            }
            if (!filter_before_flipping) {
                filter_after_flipping['start'] = null
                filter_after_flipping['end'] = new Date().getTime() / 1000
            }
            else {
                filter_after_flipping['end'] = filter_before_flipping['start']
                filter_after_flipping['start'] = filter_before_flipping['end']
            }
            this.$store.commit('SET_FILTER', filter_after_flipping)
        }
    }
}
</script>

<style>
.datepicker-custom {
    padding: 0px 8px;
    color: var(--bs-body-color);
}
    .slider.before .slider-toggle {
        margin-left:14px;
        background: var(--primary_color);
    }
    .slider.after .slider-toggle {
        margin-left:1px;
        background:white;
    }
    .slider-text {
        margin-right:.25rem;
        font-size: 83.33%;
    }
    .slider.before .slider-text {
        color:var(--primary_color);
    }
</style>