<template>
  <div id="module-picker" :class='{
            "emphasis": show.emphasis=="HomeSearchModuleSelector",
            "greyed_out": show.emphasis=="SearchBox",
            "minimized": show.emphasis=="SimilarResults",
        }' class="mw-100">
    <h5 class="mb-4" v-if="show.emphasis=='HomeSearchModuleSelector'">What are you looking for today?</h5>
    <div id="module-list">
      <Multiselect 
          :options="searchmodules"
          mode = "single"
          :canClear = "false"
          class="mb-2"
          :valueProp="'pk'"
          v-model="multiselect_searchmodule"
          @select="set_with_select">
            <template v-slot:option="{ option }">
              {{ option.display_name }}
            </template>
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                {{ value.display_name }}
              </div>
            </template>
      </Multiselect>  
    </div>
    <div id="module-buttons">
        <template v-for="searchmodule in searchmodules" :key="'searchmodule-btn-'+searchmodule.pk">
          <div @click="select_or_search(searchmodule)"
            class="btn-searchmodule"
            role="button"
            :class='{
                "active": $store.state.selected_searchmodule.pk == searchmodule.pk,
            }'
            >
              <span class="searchmodule-button-text">{{ searchmodule.display_name }}</span>
          </div>
          <GreyDivider v-if="searchmodule.pk !== searchmodules[searchmodules.length -1].pk"/>
        </template>
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import shared from "./shared";
import GreyDivider from "./GreyDivider.vue";
import Multiselect from '@vueform/multiselect'

export default {
  name: "HomeSearchModuleSelector",
  components: {GreyDivider, Multiselect},
  computed: {
    ...mapState(["searchmodules", "show"]),
    multiselect_searchmodule:{
      get: function() {return this.$store.state.selected_searchmodule.pk},
      set: function() {return true;}
    }
  },
  methods: {
    get_icon: function (result_type) {
      return shared.result_type_to_icon(result_type);
    },
    set_with_select: function(option_primary_key) {
      let searchmodule = this.searchmodules.filter(function(searchmodule) {return searchmodule.pk == option_primary_key})[0]
      this.select_or_search(searchmodule)
    },
    select_or_search: function(searchmodule) {
      this.$store.commit('SET_SEARCHMODULE', searchmodule)
      if(!this.$store.state.show.search_started) {
        this.$store.state.show['emphasis'] = 'SearchBox' // Move on UI emphasis to Search field
        document.querySelector('#smart_query').focus()
      }
      else {
        this.$store.dispatch('get_similarity')
      }
    }
  },
};
</script>

<style>
/* default */
#module-picker {
  position:absolute;
  width:100%;
  left: 50%;
  top:25%;
  -webkit-transform: translateX(-50%) translateY(25%);
  -moz-transform: translateX(-50%) translateY(25%);
  transform: translateX(-50%) translateY(25%);
}
#module-buttons {
  position:absolute;
  left:50%;
  margin-top:1rem;
  width:60%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all ease var(--slow_anim);
  display:flex;
  align-items:flex-start;
  justify-content:space-around;
}
#module-list {
  display:none;
  
}
/* MOBILE */
@media (max-width: 992px) {
  #module-list {
    display:flex; 
  }
  #module-buttons, .grey-divider {
    display:none;
  }
  #module-picker {
    position:relative;
    margin: 0.5rem;
    left: 0;
    right: 0;
    width:auto;
    transform: inherit!important;
  }
  #module-picker.minimized {
    height:42px!important;
    position:static!important;
    width:auto!important;
  }
}

#module-picker.minimized #module-buttons {
  left:0%;
  width:100%;
  margin-top:.75em;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  transform: translateX(0%)
}

.btn-searchmodule {
  color:var(--grey_text);
  overflow-y:visible;
}

.btn-searchmodule.active {
  color: var(--primary_color);
  font-weight:700;
}
.grey-divider {
  flex-shrink:1;
}
.searchmodule-button-text {
  overflow-x:visible;
  flex-basis:0;
  width: 100%;
  display:inline-block;
}

.searchmodule-button-text  {
  transition: all var(--fast_anim) ease;
}

.btn-searchmodule:hover .searchmodule-button-text {
  color: var(--primary_color);
  transform:scale(1.1);
}


/* emphasized status: */
#module-picker.emphasis {
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(50%);
    -moz-transform: translateX(-50%) translateY(50%);
    transform: translateX(-50%) translateY(50%);
}
#module-picker.emphasis h5 {
    text-align:center;
    color:var(--secondary_color)
}
#module-picker.emphasis .btn-primary:hover {
    color: var(--orange_highlight);
    border-color: var(--orange_highlight);
}
#module-picker.emphasis .btn-primary {
    color:var(--grey_text);
}


/* minimized status */
#module-picker.minimized {
  height:55px;
  position:absolute;
  width:40%;
  left: 0;
  top: .5rem;
  -webkit-transform: translateX(0%) translateY(0);
  -moz-transform: translateX(0%) translateY(0);
  transform: translateX(0%) translateY(0);
}
</style>