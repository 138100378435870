<template>
    <div :title="title" :class="classes" @click="export_list_to_xlsx()">
         {{this.label?this.label:'Export collection'}}
         <span v-if="this.icon"><i class="fa fa-file-excel ms-2"></i></span>
    </div>  
</template>

<script>
import * as XLSX from 'xlsx'
import _ from 'lodash'
import shared from './shared.js';

export default {
    name: "ExcelExport",
    props: ['collection', 'collection_records', 'label', 'icon', 'classes', 'title', 'exporting_items'],
    data: function() {return({
        "authors": {
            columns: [
                {name: "#",                 data: function(item, key) {return key}, width: 23},
                {name: "Name",           data: function(item) {return item.fields['display_name']}, width: 300},
                {name: "Affiliation",    data: function(item) {return shared.get_lki(item)?.display_name ?? 'Unknown'}, width: 300},
                {name: "Country",        data: function(item) {return item['active_affiliations']?item['active_affiliations'][0]['country_code']:'Unknown'}, width: 30},
                {name: "Email",          data: function(item) {return item['fields']['email_addresses'][0]??'Unknown'}, width: 300},
                {name: "Works #",        data: function(item) {return item.fields['works_count']}, width: 60},
                {name: "Cited #",        data: function(item) {return item.fields['cited_by_count']}, width: 60},
                {name: "Relevance",      data: function(item) {return item.fields['ranking_score']}, width: 55},
                {name: "Orcid",          data: function(item) {return item.fields['orcid']}, width: 267},
                {name: "Matching Works", data: function(item) { if(item.collection) {return _.map(item['collection']['records'], 'fields.title').join('\n')}}, width: 500},
                {name: "Author Concepts",data: function(item) { if(item.fields['x_concepts']) { return _.map(item['fields']['x_concepts'], 'display_name').join('; ')}}, width: 150},
            ]
        },
        "experts": {
            columns: [
                {name: "#",              data: function(item, key) {return key}, width: 23},
                {name: "Name",           data: function(item) {return item.fields['display_name']}, width: 300},
                {name: "Affiliation",    data: function(item) {return item['active_affiliations']?item['active_affiliations'][0]['display_name']:'Unknown'}, width: 300},
                {name: "Country",        data: function(item) {return item['active_affiliations']?item['active_affiliations'][0]['country_code']:'Unknown'}, width: 30},
                {name: "Email",          data: function(item) {return item['fields']['email_addresses'][0]??'Unknown'}, width: 300},
                {name: "Works #",        data: function(item) {return item.fields['works_count']}, width: 60},
                {name: "Cited #",        data: function(item) {return item.fields['cited_by_count']}, width: 60},
                {name: "Relevance",      data: function(item) {return item.fields['ranking_score']}, width: 55},
                {name: "Orcid",          data: function(item) {return item.fields['orcid']}, width: 267},
                {name: "Matching Works", data: function(item) { if(item.collection) {return _.map(item['collection']['records'], 'fields.title').join('\n')}}, width: 500},
                {name: "Author Concepts",data: function(item) { if(item.fields['x_concepts']) { return _.map(item['fields']['x_concepts'], 'display_name').join('; ')}}, width: 150},
            ]
        },
        "institutions": {
            columns: [
                {name: "#",              data: function(item, key) {return key+1}, width: 23},
                {name: "Name",           data: function(item) {return item.fields['display_name']}, width: 250},
                {name: "Acronym",        data: function(item) {return item.fields['display_name_acronyms']}, width: 100},
                {name: "Country",        data: function(item) {return item.fields['country_code']}, width: 60},
                {name: "Type",           data: function(item) {return item.fields['type']}, width: 30},
                {name: "City",           data: function(item) {return item.fields['geo']?item.fields['geo']['city']:'unkown'}, width: 150},
                {name: "Homepage",       data: function(item) {return item.fields['homepage_url']}, width: 150},
                {name: "Relevance",      data: function(item) {return item.fields['ranking_score']}, width: 60},
                {name: "OpenAlex ID",    data: function(item) {return item.fields['id']}, width: 150},
                {name: "ROR",            data: function(item) {return item.fields['ror']}, width: 60},
                {name: "Matching works", data: function(item) {return _.map(item['collection']['records'], 'fields.title').join('\n')}, width: 500},
            ]
        },
        "partners": {
            columns: [
                {name: "#",                      data: function(item, key) {return key+1}, width: 23},
                {name: "Name",                   data: function(item) {return item.fields['name']}, width: 250},
                {name: "City",                   data: function(item) {return item.fields['city']}, width: 250},
                {name: "Country",                data: function(item) {return item.fields['country']}, width: 250},
                {name: "Matching projects",      data: function(item) {return _.map(item['collection']['records'], 'fields.title').join('\n')}, width: 250},
                {name: "Matching project links", data: function(item) {return _.map(item['collection']['records'], 'fields.url').join('\n')}, width: 250},
            ]
        },
        "projects": {
            columns: [
                {name: "#",          data: function(item, key) {return key+1}, width: 23},
                {name: "Name",       data: function(item) {return item.fields['display_name']}, width: 250},
                {name: "Start date", data: function(item) {return new Date(1000*item['fields']['start_date']).toLocaleDateString()}, width: 100},
                {name: "URL",        data: function(item) {return item.fields['url']}, width: 150},
                {name: "Score",      data: function(item) {return item.fields['ranking_score']}, width: 30},
                {name: "Abstract",   data: function(item) {return item.fields['abstract']}, width: 500},
            ]
        },
        "referees": {
            columns: [
                {name: "#",              data: function(item, key) {return key}, width: 23},
                {name: "Name",           data: function(item) {return item.fields['display_name']}, width: 300},
                {name: "Affiliation",    data: function(item) {return item['active_affiliations']?item['active_affiliations'][0]['display_name']:'Unknown'}, width: 300},
                {name: "Country",        data: function(item) {return item['active_affiliations']?item['active_affiliations'][0]['country_code']:'Unknown'}, width: 30},
                {name: "Email",          data: function(item) {return item['fields']['email_addresses'][0]??'Unknown'}, width: 300},
                {name: "Works #",        data: function(item) {return item.fields['works_count']}, width: 60},
                {name: "Cited #",        data: function(item) {return item.fields['cited_by_count']}, width: 60},
                {name: "Relevance",      data: function(item) {return item.fields['ranking_score']}, width: 55},
                {name: "Orcid",          data: function(item) {return item.fields['orcid']}, width: 267},
                {name: "Matching Works", data: function(item) { if(item.collection) {return _.map(item['collection']['records'], 'fields.title').join('\n')}}, width: 500},
                {name: "Author Concepts",data: function(item) { if(item.fields['x_concepts']) { return _.map(item['fields']['x_concepts'], 'display_name').join('; ')}}, width: 150},
            ]
        },
        "reviewers-sage": {
            columns: [
                {name: "Institution Email", data: function(item) {return item.fields['email_addresses'][0]??'Unknown'}, width: 100},
                {name: "First Name",        data: function(item) {return item.fields['display_name'].split(' ').slice(0,1).join('') }, width: 100},
                {name: "Last Name",         data: function(item) {return item.fields['display_name'].split(' ').slice(1).join(' ') }, width: 100},
                {name: "Contact Comments",  data: function(item) {return ''}, width: 200},
                {name: "Lead Source",       data: function(item) {return ''}, width: 100},
            ]
        },
        "starthubs": {
            columns: [
                {name: "Display Name",      data: function(item) {return item.fields['display_name']}, width: 250},
                {name: "Company Website",   data: function(item) {return item.fields['company_website']}, width: 100},
                {name: "Email",             data: function(item) {return item.fields['email']}, width: 100},
                {name: "Submission_date",   data: function(item) {return new Date(1000*item.fields['creation_date_time']).toLocaleDateString()}, width: 100},
                {name: "Company Name",      data: function(item) {return item.fields['company_name']}, width: 100},
                {name: "Submission Title",  data: function(item) {return item.fields['submission_title']}, width: 100},
                {name: "Content",           data: function(item) {return item.fields['content']}, width: 100},
                {name: "Content type",      data: function(item) {return item.fields['origin_content_type']}, width: 100},
                {name: "Source URL",        data: function(item) {return item.fields['origin_url']}, width: 100},
            ]
        },
        "startups": {
            columns: [
                {name: "ID",             data: function(item) {return item.fields.id}, width: 100},
                {name: "Company Name",   data: function(item) {return item.fields['display_name']}, width: 250},
                {name: "City",           data: function(item) {return item.fields['city']}, width: 100},
                {name: "Description",    data: function(item) {return item.fields['description']}, width: 100},
                {name: "Persons",        data: function(item) {
                        return _.map(item.fields['persons'], function(person) {
                            return `${person['name']} (${person['title']})`}).join('; ')
                    }, width: 100},
                {name: "URL",            data: function(item) {return item.fields['url']}, width: 100},
                {name: "Country",        data: function(item) {return item.fields['country']}, width: 100},
                {name: "Foundation_year",data: function(item) {return item.fields['foundation_year']}, width: 100},
                {name: "Keywords",       data: function(item) {return item.fields['keywords']}, width: 100},
                {name: "Source URL",     data: function(item) {return item.fields['source_url']}, width: 100},
                {name: "Updated Date",   data: function(item) {return item.fields['updated_date']}, width: 100},
                {name: "Email",          data: function(item) {return item.fields['email']}, width: 100},
            ]
        },
        "works": {
            columns: [
                {name: "Publication Date",          data: function(item) {return item.fields['publication_date']}, width: 125},
                {name: "Title",                     data: function(item) {return item.fields['display_name']}, width: 500},
                {name: "Authors",                   data: function(item) {return _.map(item['fields']['authorships'], 'author.display_name').join('; ')}, width: 250},
                {name: "DOI",                       data: function(item) {return item.fields['doi']}, width: 250},
                {name: "Publication Type",          data: function(item) {return item.fields['type']}, width: 150},
                {name: "Source",                    data: function(item) {return item.fields['primary_location']['source']?item.fields['primary_location']['source']['display_name']:'unknown'}, width: 250},
                {name: "Abstract",                  data: function(item) {return item.fields['abstract']}, width: 500},
            ]
        },
    })},
    methods: {
        export_list_to_xlsx: function() {
            let _this = this;
            var workbook = XLSX.utils.book_new()
            // Extract Data (create a workbook object from the table)
            _.each(this.exporting_items, function(items, group) {
                try {
                    // adding an exception for Sage to fit Dynamics CRM
                    if ((group == 'Experts' || group == 'authors') && _this.$store.state.user_profile.organisation.name == 'Sage') {
                        group = 'reviewers-sage'
                    }
                    const ws = _this.create_worksheet(items, group.toLowerCase())
                    XLSX.utils.book_append_sheet(workbook , ws, group.toLowerCase());
                }
                catch(err) {
                    console.log('Unable to generate output for type:' + err)
                }
            });
            XLSX.writeFile(workbook, "GlobalCampus export "+new Date().toISOString() + ".xlsx");
        },
        create_worksheet(items, type) {
            console.log('creating worksheet of type ' + type)
            let _this = this;
            let items_output = []
            _.map(items, function(item, key) {
                let item_output = {}
                _.each(_this[type]['columns'], function(column) {
                    try {
                        item_output[column['name']] = column.data(item, key)
                    }
                    catch(err) {
                        console.log('error generating output:' + err)
                    }
                });
                items_output.push(item_output)
            });
            const ws = XLSX.utils.json_to_sheet(items_output);
            var worksheet_columns = _.map(_this[type]['columns'], function(item) {return {wpx: item.width}})
            ws['!cols'] = worksheet_columns;
            return ws
        },
    }
}

</script>

<style scoped>
.btn {
    padding: .375rem 0.75rem;
}
</style>