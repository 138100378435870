<template>
  <div :id="'tooltip'+idx" :class="'tooltip-item bg-'+tooltip.style" @click="$store.commit('REMOVE_TOOLTIP', idx)" >
    <div class="tt-arrow" data-tooltip-arrow></div>
      {{tooltip.message}}
    <div class="ms-1 float-end tt-close" role="button"><i class="fa fa-close"></i></div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import $ from 'jquery'

export default {
    name: "Tooltip",
    props: ["idx", "tooltip"],
    mounted: function() {
      var popper = $('#'+this.tooltip.anchor_element_id)[0]
      var tooltip_element = $('#tooltip'+this.idx)[0]

      createPopper(popper, tooltip_element, {
          placement: this.tooltip.placement,
          modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [8, 8],
                  },
              },
              {
                name: 'arrow',
                options: {
                    padding: 16, // 16px from the edges of the popper
                },
              },
          ],
      });
    }
}
</script>

<style>
.tt-arrow,
.tt-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tt-arrow {
  visibility: hidden;
}

.tt-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
.tooltip-item {
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  cursor:pointer;
  z-index:3;
  display:flex;
  flex-wrap:nowrap;
}
.tooltip-item[data-popper-placement^='right'] > .tt-arrow {
  left: -4px;
}
.tooltip-item[data-popper-placement^='left'] > .tt-arrow {
  right: -4px;
}
.tooltip-item[data-popper-placement^='top'] > .tt-arrow {
  bottom: -4px;
  left: calc(50% - 4px);
}
.tooltip-item[data-popper-placement^='bottom'] > .tt-arrow {
  top: -4px;
  left: calc(50% - 4px);
}
</style>