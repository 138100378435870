<template>
    <div id="topics-chart">
        <VChart ref="topic_punchcard" id="topic-punchcard" :option="option" @mouseover="capture_mouseover"/>
        <div id="topic-selector">
            <h6 class="text-center">Top topics for your query:</h6>
            <hr/>
            <div class="topic-label mb-1" :key="'topic_selector'+topic.id" v-for="topic in $store.state.query.topics">
                <input class="me-1" :id="'topic_cb'+topic.id" type="checkbox" v-model="topic.display"/>
                <label :for="'topic_cb'+topic.id">{{topic.display_name}}</label>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { ScatterChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from "echarts/renderers";
import shared from "./shared.js"

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  UniversalTransition
]);

export default {
    name:"TopicsChart",
    props: [],
    components: {VChart},
    computed: {
        topic_data: function() {return shared.topics},
        chart_data: function() {
            let _this = this;
            // this function calculates the topic scores for all Experts.
            // the topics are in descending order of query importance
            // the experts are ranked according to the current active sort
            // the data is added as [query_topic_idx, expert_idx, score] to a series
            // in total, there are 4 series, corresponding to each of the top level OpenAlex domains
            let shared_series_settings = {
                type: 'scatter',
                symbolSize: function (val) {
                    return Math.max(Math.min(50, val[2]), 5);
                },
            }
            let series = [
                {...shared_series_settings,
                    name: 'Life Sciences',                   
                    data: [],
                },
                {...shared_series_settings,
                    name: 'Social Sciences',                   
                    data: [],
                },
                {...shared_series_settings,
                    name: 'Physical Sciences',                   
                    data: [],
                },
                {...shared_series_settings,
                    name: 'Health Sciences',                   
                    data: [],
                },
            ]
            let topics = []
            // we reverse the expert results, because the coordinate system starts at the bottom, 
            // but we want the top results to be displayed at the top.
            let expert_results = _this.$store.getters.getCurrentResultList
            let expert_ids = expert_results.map(item => {return item.identifier}).reverse()           
            let displayed_query_topics = this.$store.state.query.topics.filter(topic => {return topic.display})

            // for every diplayed topic, we loop over all authors, and for each author we loop over all topics.
            _.forEach(displayed_query_topics, function(query_topic, query_topic_index) {
                topics.push(query_topic.id)
                expert_results.forEach(function(expert, expert_idx) {
                    expert.fields.topics?.forEach(function(expert_topic) {
                        if (expert_topic.id.replace('https://openalex.org/', '') == query_topic.id) {
                            let domain_id = parseInt(expert_topic.domain.id.replace('https://openalex.org/domains/', '')) - 1
                            series[domain_id]['data'].push([ 
                                query_topic_index,
                                expert_ids.length - 1 - expert_idx,
                                expert_topic.count
                            ])
                        }
                    })
                })
            })
            let chart_data_obj = {
                "series": series,
                "topics": topics,
                "experts": expert_ids
            }
            return chart_data_obj
        },
        option: function(){
            let _this = this;
            return {
                legend: {
                    left: 'left',
                    top: 'bottom'
                },
                color: _.map(shared.domain_colors, (domain) => {return domain.bg}),
                grid: {
                    left: 200,
                    top: 150,
                    right: 400,
                    containLabel: false
                },
                tooltip: {
                    show: true,
                    formatter: function(item) {
                        console.log(item)
                        if(!item.value) return
                        else {
                            let expert_id = _this.chart_data.experts[item.value[1]]
                            let topic_id = _this.chart_data.topics[item.value[0]]
                            return `${_this.$store.getters.getCurrentResultListByID[expert_id].fields.display_name} has ${item.value[2]} works in ${shared.topics[topic_id].dn}`
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    data: _this.chart_data.experts,
                    boundaryGap: false,
                    invert: true,
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show:false,
                    },
                    axisLine: {
                        show: false
                    },
                    axisPointer: {
                        show: true,
                        type: 'shadow',
                        label: {
                            formatter: function(axis_item) {
                                return _this.$store.getters.getCurrentResultListByID[axis_item.value].fields.display_name
                            }
                        },
                        animation: false
                    },
                    axisLabel: {
                        formatter: function(expert_id) {
                            return _this.$store.getters.getCurrentResultListByID[expert_id].fields.display_name
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: _this.chart_data.topics,
                    position: 'top',
                    triggerEvent: true,
                    axisPointer: {
                        show: true,
                        type: 'shadow',
                    },
                    axisLine: {show: false},
                    axisTick: {show: true},
                    axisPointer: {
                        show: true,
                        label: {
                            show:false,
                        },
                        type: 'shadow',
                        animation: false
                    },
                    axisLabel: {
                        formatter: function(x_value) {
                            return shared.topics[x_value].dn
                        },
                        rotate: -10,
                        margin: 28,
                        overflow:'visible',
                    },
                    
                },
                series: this.chart_data.series
            }
        },
    },
    methods: {
        capture_mouseover: function(event) {
            let _this = this;
            if (event.targetType == 'axisLabel' && event.componentType == 'xAxis') {
                _this.$refs.topic_punchcard.dispatchAction({
                    type: 'showTip',
                    seriesIndex: 0,
                    dataIndex: 0, // Assuming X-axis number 1 (0-based index)
                });
            }


        }
    }
}


</script>

<style>
#topics-chart {
    min-width:var(--resultlist_width);
    min-height:80vh;
    align-items:center;
    position:relative;
}
#topic-punchcard {
    position:absolute;
    left:0;
    height:calc(100vh - 250px);
    width: 100%;
}
#topic-selector {
    position:absolute;
    right: 0;
    top:20%;
    max-height:50%;
    max-width:400px;
}
.topic-label {
    max-height:1.4rem;
    max-width:400px;
    white-space:nowrap;
    overflow:hidden;
}
</style>