<template>
  <div class="mb-4" id="excluded_items_sidebar">
    <div @click="show.excluded=1-show.excluded" role="button" class="collection-type d-flex justify-content-between user-select-none align-items-center">
      <span class="sidebar-title">Excluded items ({{stored_collection.excluded_records.length}})</span>
      <span class="animate-flip"  :class="{'flip-y': show.excluded}">
        <i class="fa fa-chevron-up"></i>
      </span>
    </div>
    <div id="excluded_items_list" v-if="show.excluded">
      <span class="collection-item" :key="'excl'+record.identifier" v-for="record in stored_collection.excluded_records">
        <span class="collection-title" >{{record.fields.display_name}}</span>
        <span class="collection-item-button remove" @click="remove_from_excluded_list(record)" title="Remove from excluded records.">
          <i class="fa fa-xmark"></i>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HiddenAuthorList",
  computed: {
      ...mapState(["stored_collection"]),
  },
  data: function() {return({
    show: {excluded: 0},
  })},
  methods: {
    remove_from_excluded_list: function(record) {
      this.$store.state.stored_collection.excluded_records = 
        _.filter(this.$store.state.stored_collection.excluded_records, (excluded_record) => {
          return excluded_record.identifier !== record.identifier
        })
    }
  }
}
</script>

<style>
#excluded_items_list > .collection-item {
  color: var(--greyed_out);
}
</style>